import confirmAction from "src/decidim/confirm";
import { getMessages } from "src/decidim/i18n";
const { Rails } = window;
const createUnloadPreventer = () => {
  const preventUnloadConditions = [];
  const confirmMessage = getMessages("confirmUnload") || "Are you sure you want to leave this page?";
  const canUnload = (event) => !preventUnloadConditions.some((condition) => condition(event));
  const unloadListener = (event) => {
    if (canUnload(event)) {
      return;
    }
    event.preventDefault();
    event.returnValue = true;
  };
  const registerBeforeUnload = () => {
    window.removeEventListener("click", registerBeforeUnload);
    window.addEventListener("beforeunload", unloadListener);
  };
  const disableBeforeUnload = () => {
    window.removeEventListener("click", registerBeforeUnload);
    window.removeEventListener("beforeunload", unloadListener);
  };
  const linkClickListener = (ev) => {
    var _a;
    const link = (_a = ev.target) == null ? void 0 : _a.closest("a");
    if (!link) {
      return;
    }
    if (canUnload(ev)) {
      disableBeforeUnload();
      document.removeEventListener("click", linkClickListener);
      return;
    }
    window.exitUrl = link.href;
    ev.preventDefault();
    ev.stopPropagation();
    confirmAction(confirmMessage, link).then((answer) => {
      if (!answer) {
        return;
      }
      disableBeforeUnload();
      document.removeEventListener("click", linkClickListener);
      link.click();
    });
  };
  const formSubmitListener = (ev) => {
    var _a;
    const source = (_a = ev.target) == null ? void 0 : _a.closest("form");
    if (!source) {
      return;
    }
    if (canUnload(ev)) {
      disableBeforeUnload();
      document.removeEventListener("submit", formSubmitListener);
      return;
    }
    const button = source.closest(Rails.formSubmitSelector);
    if (!button) {
      return;
    }
    ev.preventDefault();
    ev.stopImmediatePropagation();
    ev.stopPropagation();
    confirmAction(confirmMessage, button).then((answer) => {
      if (!answer) {
        return;
      }
      disableBeforeUnload();
      document.removeEventListener("submit", formSubmitListener);
      source.submit();
    });
  };
  const registerPreventUnloadListeners = () => {
    window.addEventListener("click", registerBeforeUnload);
    document.addEventListener("click", linkClickListener);
    document.addEventListener("submit", formSubmitListener);
  };
  return {
    addPreventCondition: (condition) => {
      if (typeof condition !== "function") {
        return;
      }
      if (preventUnloadConditions.length < 1) {
        registerPreventUnloadListeners();
      }
      preventUnloadConditions.push(condition);
    }
  };
};
const unloadPreventer = createUnloadPreventer();
export const preventUnload = (condition) => unloadPreventer.addPreventCondition(condition);
